<template>
   <section class="cont">
      <!-- 面包屑  /wechatappletconfig -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>营销云</el-breadcrumb-item>
            <el-breadcrumb-item>配置</el-breadcrumb-item>
            <el-breadcrumb-item>微信小程序配置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-col class="main">
         <el-row class="allocation">
            <!--小程序 -->
            <!--小程序-->
            <el-row class="deploy" >
               <el-row class="deploy-box">
                  <el-row class="deploy-box" v-if="wechatappletconfigpath =='' ">
                     <el-row class="title">
                        <el-row class="title-text" >微信小程序配置</el-row>
                     </el-row>
                     <div class="img-box">
                        <img style="margin: 20px" src="../../../../assets/icon/subscription.png">
                        <el-input type="text" v-model="officialappletid" placeholder="请输入小程序appid" style="width: 80%" ></el-input>
                        <el-button style="margin-top: 20px"  class="bg-gradient" type="primary" @click="sweepCode('wechat-applet')" >已有小程序，立即绑定</el-button>
                        <p style="margin-top: 10px;padding: 10px;">为保证所有的功能正常使用,</p>
                        <p>授权时请把所有权限统一授权给达易住</p>
                     </div>
                  </el-row>
                  <div class="form-text" v-else>
                     <el-row class="title">
                        <el-row class="title-text" >微信小程序配置</el-row>
                     </el-row>
                     <el-form style="margin-top: 40px">
                        <el-form-item label="小程序酒店列表链接">
                           {{wechatappletconfigpath.home_page.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.home_page.path)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="小程序我的预售券链接">
                           {{wechatappletconfigpath.Presalecoupon.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.Presalecoupon.path)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="小程序酒店房型列表链接">
                           {{wechatappletconfigpath.hotel_introduce.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.hotel_introduce.path)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="小程序我的订单链接">
                           {{wechatappletconfigpath.my_order.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.my_order.path)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="小程序入住人管理链接">
                           {{wechatappletconfigpath.reside_select.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.reside_select.path)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="小程序商惠页面链接">
                           {{wechatappletconfigpath.shopping.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.shopping.path)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="小程序限时秒杀链接">
                           {{wechatappletconfigpath.shopping_seckill.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.Presalecoupon.path)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>

                     </el-form>
                     <div style="height: 40px">
                        <img  class="qrcodeMap" style="position: relative;left: 800px;top: -220px;" referrerPolicy="no-referrer" :src="wechatappletconfig.content.authorizer_info.qrcode_url + '?wx_fmt=jpeg' "  />
                        <a :href="wechatappletconfig.content.authorizer_info.qrcode_url" class="uploadbtn bg-gradient"  target="_blank" referrerPolicy="no-referrer">下载小程序二维码</a>
                     </div>


                  </div>
               </el-row>

            </el-row>
         </el-row>
      </el-col>
   </section>
</template>

<script>
import { urlObj } from "@/api/interface";
import { mapState } from 'vuex'
export default {
   name: "wechatappletconfig",
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.seeabout()
            }
         },
         deep: true
      }
   },
   computed: {
      ...mapState(['hotelInfo'])
   },
   data() {
      return {
         drawer:false,
         id: "",            //微信公众号ID
         AppId: "",              //开发者(AppID)
         AppSecret: "",          //开发者密码(appSecret)
         Token: "",              //令牌(token)
         encodingAESKey: "",     //消息加解密密钥(encodingAESKey)
         wechatappletconfig:'',//小程序配置
         wechatappletconfigpath:[],
      }
   },
   mounted() {
      this.hotelId =    this.hotelInfo.id
      this.seeabout()
      this.getAppletPages()
   },
   methods: {
      //查询配置公众号
      seeabout() {
         const url = urlObj.EnquiryPublicAccount;
         var ads = ["wechat-official","douyin-platform","douyin-scope","wechat-applet"]
         const param = {
            hotelId: this.hotelId,
            limit: this.limit,
            page: this.page,
            cfgCodes: ads,
         };
         this.$axios.post(url, param, 'json').then((res) => {
            this.tableData = res.records;
            this.tableData.forEach((item) => {
               item.content = JSON.parse(item.content)
               if (item.cfgCode === "wechat-applet") {
                  let content = item.content;
                  this.id = content.id;
                  this.AppId = content.appId;
                  this.AppSecret = content.appSecret;
                  this.Token = content.token;
                  this.encodingAESKey = content.encodingAesKey;
                  this.wechatappletconfig = item
               }
            });
            this.drawer = false
            console.log(this.wechatappletconfig)
         });
      },
      //跳转扫码授权
      sweepCode(cfgCode){
         let content ={
            authorization_info:{
               authorizer_appid:''
            }
         }
         if (cfgCode == 'wechat-official'){
            content.authorization_info.authorizer_appid = this.officialappid
         }else{
            content.authorization_info.authorizer_appid = this.officialappletid
         }

         const url = urlObj.saveWechatHotelCfg;
         console.log(url)
         const param = {
            hotelId: this.hotelId,
            cfgCode: cfgCode,
            contents:content
         };
         this.$axios.post(url, param, 'json').then((res) => {
            if (res.status == 200){
               this.getwechatconfig('/oauth2/wechat/thirdparty/getAuthURL/web')

            }
         })
      },
      //获取小程序配置
      getAppletsconfing(){
         const url = urlObj.getAppletsconfing
         const param = {
            hotelId: this.hotelId,
            hotelCode:'dayizhu'
         };
         this.$axios.post(url, param).then((res) => {
            this.getAppletPages()
         })
      },
      //获取小程序配置文件中指定的页面
      getAppletPages(){
         const url = urlObj.getAppletsconfing
         const param = {
            hotelId: this.hotelId,
            hotelCode:'dayizhu'
         };
         this.$axios.post(url, param).then((res) => {
            if (res.status == 200) {
               console.log(res.records,'res')
               this.wechatappletconfigpath = res.records
               // console.log(this.wechatappletconfigpath)
               if (!res.records.home_page.url){
                  this.getAppletsconfing()
               }
            }
         })
      },
      //复制
      copy(data) {
         const copyContent = data;
         // 创建input标签存放需要复制的文字
         const inputTag = document.createElement("input");
         // 把文字放进input中，供复制
         inputTag.value = copyContent;
         document.body.appendChild(inputTag);
         // 选中创建的input
         inputTag.select();
         // 执行复制方法， 该方法返回bool类型的结果，告诉我们是否复制成功
         const copyResult = document.execCommand("copy");
         // 操作中完成后 从Dom中删除创建的input
         document.body.removeChild(inputTag);
         // 根据返回的复制结果 给用户不同的提示
         if (copyResult) {
            this.$message.success("复制成功！"); // 自己定义的提示语
         } else {
            console.log("复制失败！"); // 自己定义的提示语
         }
      },
      //下载小程序二维码
      uploadqrcodeMap(url){
         window.open(url)
      },
   },
}
</script>


<style scoped>
.tips{
   background: rgb(92 213 81);
   color: #fff;
   font-size: 12px;
   margin-left: 15px;
   padding: 0 5px;
   border-radius: 2px;
}
.main {
   width: 100%;
   background: white;
}
.allocation {
   width: 100%;
}
.deploy {
   width: 97%;
   /*box-shadow: 0px 0px 4px #c2d7fb inset;*/
   margin: auto;
   margin-top: 2rem;
   margin-bottom: 2rem;
}
.deploy-box{
   width: 97%;
   margin: auto;
}
.title {
   width: 100%;
   height: 60px;
   line-height: 60px;
   border-bottom: 1px solid #dde0e7;
   font-size: 1.1rem;
   padding-left: 1rem;
}
.title-text{
   float: left;
   font-size: 18px;
   font-weight: 900;
}

.basic .el-row {
   font-size: 17px;
   width: 350px;
   border-bottom: 1px solid #dedede;
}
/deep/ .el-table__header{margin-top:1rem;}
.message {
   width: 100%;
   padding-bottom: 2rem;
   padding-left: 1rem;
}
.message .el-col {
   margin-top: 1.5rem;
}
.message .el-col .el-input {
   min-width: 300px;
}
/deep/ .has-gutter tr th{text-align: center; background: #f2f2f2; color: #667688;font-weight: bold;}
/deep/ tbody .el-table__row td{text-align: center;}

@media screen and (max-width: 1366px) {
   .main {
      width: 1200px;
   }
}

.form-text{
   margin:0 1rem 1rem;
}
.form-text a{
   position: relative;
   left: 365px;
   top: -200px;
   padding: 10px 15px;
   border-radius: 3px;
   text-decoration: none;
}
.text-btn{
   margin: 0 10px;
}
.bottom-btn button{
   padding: 20px 10px;
}
.qrcodeMap{
   margin: 30px 140px;
   width: 150px;
}
.uploadbtn{
   margin-left: 140px;
}
/deep/.el-form-item{
   margin-bottom: 0;
}
/deep/.el-checkbox{
   width: 33%;
   padding: 5px 0;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
   background-color: #409eff;
   border-color: #DCDFE6;
}
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label{
   color: #000;
   cursor: not-allowed;
}
.img-box{
   width: 220px;
   height: 300px;
   border: 1px solid #dedede;
   margin: 20px;
   padding: 15px;
   text-align: center;
   font-size: 12px;
}
.img-box p{
   opacity: 0.5;
}
/deep/.el-drawer__open .el-drawer.ttb{
   height: 150px;
   width: 350px;
   margin: 15% auto;
   text-align: center;
}
/deep/.el-drawer__header{
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   color: #2d2d2d;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   margin-bottom: 32px;
   border: none;
   padding: 20px;
   padding-bottom: 0;
   font-size: 18px;
}
/deep/ .el-drawer:focus {
   outline: none;
}
.el-drawer /deep/ :focus {
   outline: none;
}
/deep/.el-drawer__header > :first-child{
   outline: none;
}
/deep/el-drawer__wrapper{
   outline: none;
}
</style>
