import { render, staticRenderFns } from "./wechatappletconfig.vue?vue&type=template&id=c4a18c38&scoped=true&"
import script from "./wechatappletconfig.vue?vue&type=script&lang=js&"
export * from "./wechatappletconfig.vue?vue&type=script&lang=js&"
import style0 from "./wechatappletconfig.vue?vue&type=style&index=0&id=c4a18c38&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4a18c38",
  null
  
)

export default component.exports